
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      
        <p>
          Welcome to trashmydata.com
        </p>
        <p style={{ color: 'orange', fontWeight: 'bold' }}>
          This site is under construction!
        </p> {/* New "Under Construction" message */}
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          
        </a>
      </header>
    </div>
  );
}

export default App;

